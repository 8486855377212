<template>
  <a-row>
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1 faq-content">
      <a-tabs
        :active-key="$route.query.tab || 'content'"
        class="main-tabs"
        type="card"
        @change="$router.push({ query: { tab: arguments[0] } })"
      >
        <a-tab-pane key="content">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
                <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                  Перевести копию на
                  {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>

          <!-- title -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model.number="form.title[langTab]"
                placeholder="Введите название"
                spellcheck="false"
                class="form_title-input"
              />
              <div v-if="!form.title[langTab]" class="error-text">
                Заполните поле
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
        </a-tab-pane>
      </a-tabs>

      <a-row class="form-buttons__action" type="flex">
        <a-col :span="6" style="text-align: right; padding-right: 8px" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />Отмена
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить" : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
export default {
  data: () => ({
    langTab: $langPrefix,
    pending: false,
    loading: false,
    form: {
      title: {
        uz: "",
        oz: "",
        ru: "",
        en: "",
        qr: ""
      },
      is_active: true
    }
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!this.$route.params.id) {
        return
      }
      this.loading = true
      try {
        const r = await this.$api.get(
          "/admin/current-regulation/normative-legal-act/" +
            this.$route.params.id +
            "/detail/"
        )
        this.form = {
          ...r.data,
          title: {
            ru: r.data && r.data.title_ru,
            en: r.data && r.data.title_en,
            oz: r.data && r.data.title_oz,
            uz: r.data && r.data.title_uz,
            qr: r.data && r.data.title_qr
          }
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    },
    // tabPaneChangeHandler(arg) {
    //   this.tabPane = arg
    // },

    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }
      } catch (error) {
        console.log("translitAction error", error)
      }
    },

    async submit() {
      this.pending = true
      try {
        let newData = cloneDeep(this.form)
        newData = {
          ...newData,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr,
          type: this.$route.params.type
        }
        delete newData.title
        let f = new FormData()

        Object.keys(newData).forEach((key) => {
          f.append(key, newData[key])
        })

        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/current-regulation/normative-legal-act/" +
                this.form.id +
                "/update/"
              : "/admin/current-regulation/normative-legal-act/create/",
          method: this.form && this.form.id ? "patch" : "post",
          data: f
        }
        const r = await this.$api[createOrUpdate.method](
          createOrUpdate.url,
          createOrUpdate.data
        )
        if ((r && r.status === 200) || (r && r.status === 201)) {
          this.$message.success("Данные сохранены")
          this.$router.push({ name: "laws" })
        }
      } catch (e) {
        console.error(e)
        this.pending = false
      }

      this.pending = false
    }
  }
}
</script>
